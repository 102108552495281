import React, { Component } from 'react';
import {Link,Navigate } from "react-router-dom";
import logo from "../../assets/images/brand/logo.png";
import NABILogo from "../../assets/images/brand/NABI-Logo.jpg";
import startupindia from "../../assets/images/brand/startup-india-4.jpeg";
import Rs99  from "../../assets/images/brand/Rs.99.png";

class TopHeader extends Component {

     
   
    render() {
        return (

            <>
            <div className="header-top home-three-top w-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className="left-top ">
                                <ul>
                                    <li className="pt-8">Myvets Integrated Solutions</li>
                                  
                                    {/* <li><i className="fa fa-phone"/>+91 90820 36384</li>
                                    <li><i className="fa fa-envelope" aria-hidden="true"></i> admin@evetscare.com </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-xs-12 headline-header">
                            <ul>
                               <li>Stray dog consultation at <span class="blink-text"> <img src={Rs99} /></span></li>
                            </ul>      
                        </div>    
                        <div className="col-lg-4 col-sm-6 col-xs-12 desktop-warpper">
                            <div className="right-top ">
                                <ul>
                                    <li><img alt="NABI" className="img-fluid" src={NABILogo}/> </li>
                                    <li> <img alt="Startup India" className="img-fluid startup-india-img" src={startupindia}/>
                                    </li>
                                   {/* <li><i className="fa fa-phone"/>+91 90820 36384</li> */}
                                    <li className="pt-13"><i className="fa fa-envelope" aria-hidden="true"></i> admin@evetscare.com </li>
                                    {/* <li><a href="#" target="_blank"><i className="fab fa-facebook"></i></a></li>
                                    <li><a href="#" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                                    <li><a href="#" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>	     	
                </div>
             </div>

            </>
        );
    }
}

export default TopHeader;