import React,{useRef} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from "react-router-dom";
import TopHeader from "./components/Header/TopHeader.js";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import "./assets/css/web-common.css";
import "./assets/css/HeaderFooter.css";
import MainHeader from "./components/Header/MainHeader.js";
import Footer from "./components/Footer/Footer.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App(){
  // let routes = useRoutes([
  //   { path: "/", element: <HomePageWeb/> },
  //   { path: "/DoctorSearch", element: <DoctorSearch /> },
  //   { path: "/AppointmentBooking", element: <AppointmentBooking /> },
  //   { path: "/BookingSuccess", element: <BookingSuccess/> },
  //   { path: "/Invoice", element: <Invoice/> },
  //   { path: "/Checkout", element: <Checkout/> },
  //      // { path: "component2", element: <Component2 /> },
  //   // ...
  // ]);
  // return routes;

  const HomePageWeb = React.lazy(()=>import("./components/Pages/HomePageWeb"));
  const DoctorSearch = React.lazy(()=>import("./components/Pages/DoctorSearch"));
  const DoctorProfile = React.lazy(()=>import("./components/Pages/DoctorProfile"));
  const AppointmentBooking = React.lazy(()=>import("./components/Pages/Booking/AppointmentBooking"));
  const BookingSuccess = React.lazy(()=>import("./components/Pages/Booking/BookingSuccess"));
  const Invoice = React.lazy(()=>import("./components/Pages/Booking/Invoice"));
  const Checkout = React.lazy(()=>import("./components/Pages/Booking/Checkout"));
  const AboutUs = React.lazy(()=>import("./components/Pages/AboutUs"));
  const Login = React.lazy(()=>import("./components/Pages/Login.js"));
  const SignUp = React.lazy(()=>import("./components/Pages/SignUp.js"));
  const ScreeningForm = React.lazy(()=>import("./components/Pages/Booking/ScreeningForm.js"));
  const OwnerAnimalProfile = React.lazy(()=>import("./components/Pages/PatientDashboard/OwnerAnimalProfile.js"));
  const ChangePassword = React.lazy(()=>import("./components/Pages/PatientDashboard/ChangePassword.js"));
  const MyAppointments = React.lazy(()=>import("./components/Pages/PatientDashboard/MyAppointments.js"));
  const RefundStatus = React.lazy(()=>import("./components/Pages/PatientDashboard/RefundStatus.js"));
  const JoinConsultation= React.lazy(()=>import("./components/Pages/PatientDashboard/JoinConsultation"));
  const MainShop= React.lazy(()=>import("./components/Pages/Shop/MainShop.js"));
  const SingleProduct= React.lazy(()=>import("./components/Pages/Shop/SingleProduct.js"));
  const Cart= React.lazy(()=>import("./components/Pages/Shop/Cart.js"));
  const VendorSearch = React.lazy(()=>import("./components/Pages/VendorSearch.js"));
  const VendorProfile = React.lazy(()=>import("./components/Pages/VendorProfile.js"));
  const ComingSoon = React.lazy(()=>import("./components/Pages/ComingSoon.js"));
  const ContactUs = React.lazy(()=>import("./components/Pages/ContactUs.js"));
  const ForgotPassowrd = React.lazy(()=>import("./components/Pages/ForgotPassword.js"));

  const myRef = useRef();

  function callchanges(){
    console.log('changes called');
    debugger;
    myRef.current.childMethod();
  }

  return(
    <div>
      <TopHeader   /> 
      <MainHeader ref={myRef} />
          <Routes>
            <Route path="/" 
                  element={
                      <React.Suspense fallback={<div>Loading....</div>}>
                        <HomePageWeb/>
                      </React.Suspense>
                  } 
            />
            <Route path="/login" 
                element={
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <Login data={callchanges} />
                    </React.Suspense>
                } 
            />
             <Route path="/SignUp" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <SignUp/>
                  </React.Suspense>
              } 
            />

            <Route path="/DoctorSearch" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <DoctorSearch/>
                  </React.Suspense>
              } 
            />
             <Route path="/DoctorProfile" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <DoctorProfile/>
                  </React.Suspense>
              } 
            />
             <Route path="/AppointmentBooking" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <AppointmentBooking/>
                  </React.Suspense>
              } 
            />
             <Route path="/BookingSuccess" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <BookingSuccess/>
                  </React.Suspense>
              } 
            />
              <Route path="/Invoice" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <Invoice/>
                  </React.Suspense>
              } 
            />
             <Route path="/Checkout" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <Checkout/>
                  </React.Suspense>
              } 
            />
             <Route path="/AboutUs" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <AboutUs/>
                  </React.Suspense>
              } 
            />

            <Route path="/Screening" 
              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <ScreeningForm/>
                  </React.Suspense>
              } 
            />  

          <Route path="/Profile" 
                element={
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <OwnerAnimalProfile/>
                    </React.Suspense>
                } 
              />  

            <Route path="/MyAppointments"

              element={
                  <React.Suspense fallback={<div>Loading....</div>}>
                    <MyAppointments/>
                  </React.Suspense>
              } 
            />    

            <Route path="/ChangePassword" 
                element={
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <ChangePassword/>
                    </React.Suspense>
                } 
              /> 

             <Route path="/ForgotPassowrd" 
                element={
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <ForgotPassowrd/>
                    </React.Suspense>
                } 
              />

              <Route path="/RefundStatus" 
                element={
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <RefundStatus/>
                    </React.Suspense>
                } 
              />
              <Route path="/JoinConsultation" 
                element={
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <JoinConsultation/>
                    </React.Suspense>
                } 
              />  
               <Route path="/shop"
              element= {
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <MainShop/>
                    </React.Suspense>
              }
              />  
              <Route path="/shop/product"
              element= {
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <SingleProduct/>
                    </React.Suspense>
              }
              />   
               <Route path="/shop/cart"
              element= {
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <Cart/>
                    </React.Suspense>
              }
              />  

              <Route path="/vendorsearch"
                   element= {
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <VendorSearch />
                    </React.Suspense>
              }
              /> 

            <Route path="/VendorProfile"
                   element= {
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <VendorProfile />
                    </React.Suspense>
              }
              /> 
              <Route path="/ComingSoon"
                   element= {
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <ComingSoon />
                    </React.Suspense>
              }
              />
                <Route path="/ContactUs"
                   element= {
                    <React.Suspense fallback={<div>Loading....</div>}>
                      <ContactUs />
                    </React.Suspense>
              }
              />
      </Routes>    
      <Footer/>
	   <ToastContainer position="bottom-right"  />
    </div>
  )

};

// const AppWrapper = () => {co
//   return (
//     <>
//       <TopHeader /> 
//       <MainHeader/>
//         {/* <Router>
//           <HomePageWeb />
//         </Router> */}

//         <Routes>

//         </Routes>

//       <Footer/>
//     </>
//   );
// };
