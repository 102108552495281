

const vaName="UserLoginDetails";

export function getUserId(){
    return JSON.parse(localStorage.getItem(vaName)).usermaster.id;
}
export function getUserName(){
    return JSON.parse(localStorage.getItem(vaName)).usermaster.name.split(' ')[0];
}
export function getUserFullName(){
    return JSON.parse(localStorage.getItem(vaName)).usermaster.name;
}
export function getUserMaster(){
    return JSON.parse(localStorage.getItem("UserLoginDetails")).usermaster;
}
export function removeAuth()
{
    localStorage.removeItem(vaName);
}

export function isUserLoggedIn()
{
    if(localStorage.getItem(vaName)==null)
    {
        return false;
    }
    return true;
}