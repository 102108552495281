import React from "react";
import logo from "../../assets/images/brand/footer-logo.png";
import {Link,Navigate } from "react-router-dom";
import NABILogo from "../../assets/images/brand/NABI-Logo.jpg";
import startupindia from "../../assets/images/brand/startup-india-4.jpeg";

const Footer = () =>{
	return(
		<div className="footer footer-one">
			<div className="footer-top" >
				<div className="container">
					<div className="row">
					   <div className="col-lg-4  col-lg-4 col-md-4">
							<div className="footer-widget footer-about">
						    	 {/* <h2 className="footer-title"><span className="remove-bold"> Operational Partner </span> <br/>Myvets Integrated Solutions</h2>  */}
								<div className="footer-logo">									
									<img alt="VET" className="img-fluid" src={logo}/>
									
									 {/* <div className="paward-by-logo-footer">										
										<img alt="Vendor service" className="img-fluid startup-india-img" src={startupindia}/>
									    <img alt="Vendor service" className="img-fluid" src={NABILogo}/>
									</div>   */}
								</div>
								<div className="footer-about-content">
									<p> </p>
									<div className="social-icon">
										<ul>
											<li><a href="#" target="_blank"><i className="fab fa-facebook"></i> </a>
											</li>
											<li><a href="#" target="_blank"><i className="fab fa-linkedin"></i></a>
											</li>
											<li><a href="#" target="_blank"><i className="fab fa-instagram"></i></a>
											</li>
											<li><a href="#" target="_blank"><i className="fab fa-twitter"></i> </a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div> 
						{/* <div className="col-lg-3 col-md-6">
							
						</div> */}
						<div className="col-lg-4  col-lg-4 col-md-4">
					    	<div className="footer-widget">
							<h2 className="footer-title">For Patients</h2>
                            
						     	<ul>
									<li><Link to="/DoctorSearch">Search for Doctors</Link></li>
									<li><Link to="/Login">Login</Link></li>
									<li><Link to="/SignUp">Register</Link></li>
									{/* <li><Link to="/">Packages</Link></li> */}
								</ul>
						    </div>		
						</div>
                        <div className="col-lg-4 col-md-4">
					    	<div className="footer-widget">
							<h2 className="footer-title">Quick Links</h2>                            
						     	<ul>
									{/* <li><Link to="/">Shop</Link></li> */}
									<li><Link to="/AboutUs">About E-VETS Care</Link></li>
									<li><Link to="/ContactUs">Contact Us</Link></li>
									<li><Link to="/">FAQ's</Link></li>
							
								</ul>
						    </div>		
						</div>
						{/* <div className="col-lg-3 col-md-6">
							<div className="footer-widget footer-contact">
								<h2 className="footer-title">Contact Us</h2>
								<div className="footer-contact-info">
									<div className="footer-address"><span><i className="fa fa-map-marker" aria-hidden="true"></i></span>
										<p>5, Khanda Colony, Sector - 8, Greater Khanda, Panvel, Navi Mumbai, Maharashtra 410206</p>
									</div>
									<p><i className="fa fa-phone fa-rotate-90"/>
									<a href="#" >+91 90820 36384</a></p>
									<p className="mb-0"><i className="fa fa-envelope" aria-hidden="true"></i>
									admin@myvetsapp.com</p>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="container">
					<div className="copyright">
						<div className="row">
							<div className="col-md-6 col-lg-6">
								<div className="copyright-text">
									<p className="mb-0">Product copyright reserved with <b> PradaTech Pvt Ltd.</b>
                                    </p>
								</div>
							</div>
							<div className="col-md-6 col-lg-6">
								<div className="copyright-menu">
									<ul className="policy-menu">
										<li><a href="#">Terms and Conditions</a>
										</li>
										<li><a href="#">Policy</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Footer;